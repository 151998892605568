import React from 'react';
import { withApollo } from '@lib_apollo';
import { withTranslation } from '@i18n';
import Core from '@core_modules/pages/401/core';

const Page = (props) => (<Core {...props} />);

Page.getInitialProps = () => ({
    namespacesRequired: ['common'],
});

export default withApollo({ ssr: false })(withTranslation()(Page));
