import Layout from '@layout';
import Content from '@core_modules/pages/401/components';

const Error401 = (props) => {
    const {
        pageConfig,
    } = props;

    // eslint-disable-next-line react/destructuring-assignment
    const title = 'Forbidden';

    const config = {
        title,
    };
    return (
        <Layout pageConfig={pageConfig || config} {...props}>
            <Content {...props} title={title} />
        </Layout>
    );
};

export default Error401;
